.ai-banner{

    background-image: url(./../Static/Images/aibanner.gif);
    background-attachment: fixed;
    background-size: cover;
    height: 700px;
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
}
.ai-header{

    width: 40%;
    display: flex;
    justify-content: center;
}
.ai-header>h1{

    font-size: 60px;
    color: white;
    font-family: 'Roboto Slab', serif;
    text-align: justify;
        
}

.ai-define{

    background-image: url(./../Static/Images/aidefine.jpg);
    background-size: cover;
    height: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    opacity: 0.8;
}

.aidefine-list{

    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 40px;
    justify-content: space-evenly;
    align-items: center;

    
}

.aidefine-item{

    border: 2px solid black;
    box-shadow: 4px 6px black;
    border-radius: 2rem;
    width: 40%;
    height: auto;
    padding: 20px;
    background-color: white;
    opacity: 0.9;

}

.aidefine-content>p{

    font-size: 20px;
    text-align: justify;
    color: black;

}

.ai-services{

    background-image: url('../../src/Static/Images/aiservice.gif');
    background-size: cover;
    width: 100%;
    height: 740px;
    padding: 40px;
}

.aiservices-header>h1{
    border-bottom: 1.5px solid white;
    color: white;
    font-weight: 400;
    text-align: center;
}

.aiservicesitem-header>h3{

    font-weight: 550;
    border-bottom: 1.5px solid gray;
    padding: 5px;

}

.aiservices-list{

    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 30px;
    justify-content: space-evenly;
}

.aiservices-item{

    border: 2px solid black;
    box-shadow: 4px 6px black;
    border-radius: 2rem;
    width: 24%;
    height: auto;
    padding: 20px;
    background-color: white;
    opacity: 0.8;
}

.aiservicesitem-content>p{

    text-align: justify;
    padding: 20px;
    font-size: 16px;
    font-weight: 545;
    color: black;


}


    @media screen and (max-width:768px){

        .ai-banner{
            background-image: url('../../src/Static/Images/aimobbanner.gif');
            background-size: cover;
            width: 100%;
            height: 500px;
        }
    
        .ai-header{
    
            width: 100%;
        }
    
        .ai-header>h1{
    
            font-size: 40px;
    
        }
    
        .ai-define{
            width: 100%;
            height: auto;
        }
    
        .aidefine-item{
            width: 100%;
            margin-bottom: 50px;
        }
    
        .aidefine-content>p{
            font-size: 12px;
        }
    
        .aidefine-gif>img{
            height: 200px;
            width: 300px;
            margin-bottom: 30px;
        }
        .ai-services{

            height: auto;

        }
        .aiservices-item{
            width: 100%;
            height: auto;
            margin-bottom: 20px;
        }

        .aiservicesitem-content>p{
            font-size: 13px;
        }
    
    
    
    
    
    
    }

