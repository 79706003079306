.blockchain-banner{

    background-image: url(../../src/Static/Images/blockchain.jpg);
    background-size:cover;
    background-attachment: fixed;
    width: 100%;
    height: 750px;
}
.blockchain-definition{

    background-image: url(../../src/Static/Images/blockdef.jpg);
    background-size:cover;
    width: 100%;
    height: auto;
    padding: 40px;

}
.blockchain-definitionlist{

    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;



   
}

.blockchain-img>img{

    height: 280px;
    width: 280px;
}
.blockchaindefine-box{

    border: 2px solid white;
    box-shadow: 4px 6px black;
    border-radius: 2rem;
    width: 40%;
    height: auto;
    padding: 30px;
}

.blockchaindefine-content>p{

    color: white;
    font-size: 20px;
    font-family: monospace;
    text-align: justify;
}

.blockchain-explain{

    background-image: url('../../src/Static/Images/giphys.gif');
    background-size: cover;
    background-attachment: fixed;
    height: auto;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
}

.blockchainexplain-header>h1{
    border-bottom: 1px solid white;
}

.blockchainexplain-list{

    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    width: 100%;
    padding: 30px;
}

.blockchainexplain-item{

    height: auto;
    width: 50%;
    border: 2px solid white;
    box-shadow: 2px 4px white;
    border-radius: 2rem;
    padding: 30px;
    opacity: 0.9;
    background-color: #20373F;
    
}

.blockchainexplain-header>h1{
    color: white;
    text-align: center;
    
}

.blockchainexplain-item>p{

    color: white;
    font-size: 20px;
    text-align: justify;
    font-family: monospace;
}


.blockchain-services{

    background-image: url('../../src/Static/Images/blockservice.png');
    background-size: cover;
    width: 100%;
    height: 740px;
    padding: 40px;
}

.blockchainservices-header>h1{
    border-bottom: 1.5px solid gray;
    text-align: center;
}

.blockchainservices-list{

    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 30px;
    justify-content: space-evenly;
}

.blockchainservices-item{

    border: 2px solid black;
    box-shadow: 4px 6px black;
    border-radius: 2rem;
    width: 30%;
    height: auto;
    padding: 20px;
    background-color: white;
    opacity: 0.8;
}

.blockchainservicesitem-content>p{

    text-align: justify;
    padding: 20px;
    font-size: 16px;
    font-weight: 600;
    color: black;


}

@media screen and (max-width:768px){

    .blockchain-banner{

        background-image: url('../../src/Static/Images/blockchainmob.jpg');
        background-size: cover;
        background-attachment: scroll;
        width: 100%;
        height: 500px;
    }

    .blockchain-definition{

        width: 100%;
        height: auto;
       
    }

    .blockchaindefine-content>p{
        font-size: 14px;
    }

    .blockchain-definitionlist{

        width: 100%;
        height: auto;
        display: flex;
        flex-flow: column;
        justify-content: space-evenly;
    }

    .blockchain-img>img{
        display: none;
    }

    .blockchaindefine-box{

        height: auto;
        width: 100%;
    }

    .blockchain-explain{
        width: 100%;
    }
    .blockchainexplain-list{

        width: 100%;

    }

    .blockchainexplain-item {

        width:100%;
        height: auto;
    }


    .blockchain-services{

        height: auto;
    }

   .blockchainservices-list{
       width: 100%;
       padding: 5px;
   }

   .blockchainservices-item{

        width: 100%;
        margin-bottom: 25px;
   }

   .blockchainexplain-item>p{
       font-size: 13px;
   }

   
.blockchainservicesitem-content>p{
    font-size: 13px;
}

}