.banner {

  background: url(../Static/Images/cyber.jpg) no-repeat center;
  background-size: cover;
  background-attachment: fixed;
  height: 600px;
  width: 100%;

  text-align: center;
  color: white;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  justify-items: center;
  margin: 0 auto;
}

.header>h1 {

  padding-left: 120px;
  padding-top: 200px;
  font-size: 70px;
  color: white;

  font-family: 'Roboto Slab', serif;
  /* font-family: 'Crimson Pro', serif; */
  /* font-family: 'Libre Baskerville', serif;  */


}

.header>p {
  font-size: 30px;
  color: lightgrey;
  font-weight: 600;
  padding-left: 100px;
}

.Services {
  background-image: url(../Static/Images/services.jpg);
  height: auto;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  flex-wrap: wrap;
  justify-items: center;
  margin: 0 auto;
  padding: 30px;
}

.Services-header>h1 {


  padding: 20px;
  color: #173F5F;
  font-weight: 700;
  font-family: 'Libre Baskerville', serif;


}



.services-itemlist {

  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  width: 98%;
  /* text-align: justify; */
  justify-content: space-evenly;



}

.item-box {

  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  border: 2px solid black;
  border-radius: 2rem;
  width: 24%;
  height: auto;
  justify-content: center;
  padding: 20px;
  text-align: center;
  background-color: #173F5F;
  color: white;
  margin: 20px;


}

.item-heading {

  padding-bottom: 20px;
}

.item-content {
  text-align: justify;
}

.icons>a {
  font-size: 30px;
  text-align: center;
  color: white;

}

.explore {

  padding: 10px;
}


.hello>img {

  padding-top: 80px;
  /* height: 400px;
    width: 300px; */
}

.portfolio {

  display: flex;
  flex-flow: column;
  background-image: url(../Static/Images/ab.jpg);
  background-size: cover;
  align-items: center;
  justify-content: space-evenly;
  height: auto;
  width: 100%;




}

.product-list {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  width: 95%;
  padding-top: 50px;
}



.hvrbox,
.hvrbox * {
  box-sizing: border-box;
}

.hvrbox {
  position: relative;
  display: inline-block;
  overflow: hidden;
  height: 350px;
  width: 45%;
  margin: 0 auto;
  margin-bottom: 1rem;


}

.hvrbox img {
  height: 350px;
  width: 300px;
  padding: 20px;
  text-align: center;
}

.hvrbox .hvrbox-layer_bottom {
  display: block;
  margin: 0 auto;
}

.hvrbox .hvrbox-layer_top {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 15px;
  -moz-transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.hvrbox:hover .hvrbox-layer_top,
.hvrbox.active .hvrbox-layer_top {
  opacity: 1;
}

.hvrbox .hvrbox-text {
  text-align: center;
  font-size: 18px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.hvrbox .hvrbox-text_mobile {
  font-size: 15px;
  border-top: 1px solid rgb(179, 179, 179);
  /* for old browsers */
  border-top: 1px solid rgba(179, 179, 179, 0.7);
  margin-top: 5px;
  padding-top: 2px;
  display: none;
}

.hvrbox.active .hvrbox-text_mobile {
  display: block;
}



.portfolioHead h1 {

  text-align: center;
  color: white;
  /* color: #173F5F; */
  font-weight: 700;
  font-family: 'Libre Baskerville', serif;





}

.hvrbox-text>a>i {

  padding-left: 13px;
  font-size: 31px;
  color: white;




}


.hvrbox-text>p {

  padding-top: 16px;
  font-size: 1.3rem;
  color: white;
}


.Address {
  /* background:lightgrey; */
  background: url(../Static/Images/build.png) no-repeat center;
  background-size: cover;
  height: 600px;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  flex-wrap: wrap;
  justify-items: center;
  margin: 0 auto;

}

.Address-header>h1 {

  text-align: center;
  color: #025062;
  font-weight: 700;
  font-family: 'Libre Baskerville', serif;

}

.Address-list {

  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  width: 98%;
  justify-content: space-evenly;
  padding-top: 80px;

}

.Address-item {

  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  width: 25%;
  height: 300px;
  justify-content: center;
  padding: 10px;
  text-align: center;
  border: 2px solid black;
  border-radius: 15%;
  box-shadow: 5px 5px;
  background-color: lightseagreen;
  opacity: 0.7;




}

.Address-icon {

  font-size: 50px;
  padding-bottom: 15px;
  color: black;
}

.Address-content {

  font-size: 1.5rem;
  color: black;
  font-weight: 700;
}

@media screen and (max-width:768px) {

  .banner {

    width: 100%;
    height: 470px;
    justify-content: center;


  }

  .hello>img {

    padding-right: 30px;
    margin-bottom: 40px;
  }

  .header>h1 {

    width: 100%;
    font-size: 35px;
    padding-left: 20px;
  }

  .header>p {

    width: 100%;
    font-size: 20px;
    text-align: center;
    padding-right: 87px;

  }

  .item-box {

    width: 100%;
    height: auto;
    border-radius: 2rem;

    margin: 6px;

  }

  .portfolio {
    width: 100%;
    height: auto;
  }

  .product-list {

    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    justify-content: space-evenly;

  }


  .hvrbox {


    height: auto;
    width: 100%;
  }

  .hvrbox-layer_top hvrbox-layer_slideup {
    width: 100%;
    height: auto;
  }

  .Address {

    height: auto;
    width: 100%;

  }

  .Address-list {

    justify-content: space-evenly;
  }

  .Address-item {

    width: 70%;
    margin: 10px;
    height: 280px;
  }
}