.rpa-banner{

    background-image: url(./../Static/Images/rpabanner.jpg);
    background-attachment: fixed;
    background-size: cover;
    height: 700px;
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
}
.rpa-header{

    width: 40%;
    display: flex;
    justify-content: center;
}
.rpa-header>h1{

    font-size: 60px;
    color: white;
    font-family: 'Roboto Slab', serif;
    text-align: justify;
        
}

.rpa-define{

    /* background-image: url(./../Static/Images/aidefine.jpg);
    background-size: cover; */
    height: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    opacity: 0.8;
}

.rpadefine-list{

    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 40px;
    justify-content: space-evenly;
    align-items: center;

    
}

.rpadefine-item{

    border-right: 6px solid gray;
    width: 50%;
    height: auto;
}
.rpadefine-itembox{

    border: 2px solid black;
    box-shadow: 4px 6px black;
    border-radius: 2rem;
    width: 70%;
    height: auto;
    padding: 20px;
    background-color: #679EBD;
    
    

}

.rpadefine-content>p{

    font-size: 20px;
    text-align: justify;
    color: black;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

}

.rpa-services{

    background-image: url(./../Static/Images/rpaservice.gif);
    background-size: cover;
    height: auto;
    width: 100%;
    padding: 50px;
}

.rpaservices-header>h1{
    border-bottom: 1.2px solid black;
    padding-bottom: 25px;
    text-align: center;
}

.rpaservices-list{

    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
}

.rpaservices-item{

    border: 2px solid black;
    box-shadow: 4px 6px black;
    border-radius: 2rem;
    width: 35%;
    height: auto;
    padding: 20px;
    background-color: white;
    opacity: 0.9;
    margin: 20px;

}

.rpaservicesitem-header>h3{

    font-weight: 600;
    padding-bottom: 15px;
    border-bottom: 1px solid gray;
}

.rpaservicesitem-content>p{

    font-weight: 700;
    color: black;
    text-align: justify;
    font-size: 17px;
}

@media screen and (max-width:768px){

    .rpa-banner{
        width: 100%;
        height: 500px;
    }

    .rpa-header{

        width: 100%;
    }

    .rpa-header>h1{

        font-size: 40px;

    }

    .rpa-define{
        width: 100%;
        height: 600px;
    }

    .rpadefine-item{
        width: 100%;
        border-right: none;
    }
    .rpadefine-itembox{
        width: 100%;
        margin-bottom: 50px;
        
    }

    .rpadefine-content>p{
        font-size: 12px;
    }

    .rpadefine-gif>img{
        height: 300px;
        width: 300px;
        
    }
    .rpa-services{

        height: auto;

    }

    .rpaservices-header>h1{

        font-size: 20px;
        font-weight: 600;
    }
    .rpaservices-item{
        width: 100%;
        height: auto;
        margin-bottom: 20px;
    }

    .rpaservicesitem-header>h3{

        font-size: 16px;
    }
    .rpaservicesitem-content>p{
        font-size: 13px;
    }






}

