.qatesting-banner{

    background-image: url(./../Static/Images/testing.png);
    background-attachment: fixed;
    background-size: cover;
    height: 650px;
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
}
.qatesting-header{
    width: 40%;
    background: rgba(6, 59, 4, 0.7);
    height: 650px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8;

}
.qatesting-header>h1{

    font-size: 60px;
    color: white;
    font-family: 'Roboto Slab', serif;
            
}

.qatesting-define{
    background-image: url(./../Static/Images/testingdef.png);
    background-size: cover;
    height: 500px;
    width: 100%;
    display: flex;
    align-items: center;
}

.qatestingdefine-list{

    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 40px;
    justify-content: space-evenly;
   

    
}

.qatestingdefine-item{

    border: 2px solid black;
    box-shadow: 4px 6px whitesmoke;
    border-radius: 2rem;
    width: 40%;
    height: 195px;
    padding: 20px;
    background-color: #00318B;
    opacity: 0.85;
   

}

.qatestingdefine-content>p{

    font-size: 20px;
    text-align: justify;
    color:white;

}

.qatestingdefine-gif>img{
    height: auto;
    width: 350px;
}

.testing-services{

    background-image: url(./../Static/Images/testingservice.gif);
    background-size: cover;
    height: auto;
    width: 100%;
    padding: 50px;
}

.testingservices-header>h1{
    border-bottom: 1.2px solid black;
    padding-bottom: 25px;
    text-align: center;
}

.testingservices-list{

    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
}

.testingservices-item{

    border: 2px solid black;
    box-shadow: 4px 6px black;
    border-radius: 2rem;
    width: 35%;
    height: auto;
    padding: 20px;
    background-color: white;
    opacity: 0.8;
    margin: 20px;

}

.testingservicesitem-header>h3{

    font-weight: 600;
    padding-bottom: 18px;
}

.testingservicesitem-content>p{

    font-weight: 700;
    color: black;
    text-align: justify;
    font-size: 17px;
}

@media screen and (max-width:768px){

    .qatesting-banner{
        width: 100%;
        height: auto;
        background-image: url(../../src/Static/Images/t.gif);
        background-size: cover;

    }

    .qatesting-header{

        width: 100%;
    }

    .qatesting-header>h1{

        font-size: 50px;

    }

    .qatesting-define{
        width: 100%;
        height: auto;
    }

    .qatestingdefine-item{
        width: 100%;
        margin-bottom: 20px;
        height: auto;
    }

    .qatestingdefine-content>p{
        font-size: 12px;
    }

    .qatestingdefine-gif>img{
        height: 200px;
        width: 350px;
        margin-bottom: 60px;
    }

   .testingservices-item{
        width: 100%;
        height: auto;
    }

    .testingservicesitem-content>p{

        font-size: 13px;
    }






}