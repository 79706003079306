*{
    
    margin:0;
    padding: 0;
    font-family:sans-serif;
    box-sizing: border-box;
    
    text-decoration:none; 
}


.Usitbanner-img{

    background: linear-gradient(
                     rgba(20,20,20, .5), 
                     rgba(20,20,20, .5)),
                     url(../Static/Images/write-593333_960_720.jpg);
                     background-size: cover;
                     background-attachment: fixed;
                     height: 400px;
                     width:100%;
                     
                     text-align: center;
                     color:white;
                     display: flex;
                     flex-flow: column;
                     align-items: center;
                     align-content: center;
                     justify-content: center;
                     flex-wrap: wrap;
                     justify-items: center;
                     margin: 0 auto;

    
}
.Usitbanner-img h1{
    font-size: 6.5rem;
}

.Usitbanner-img p{

    font-size: 18px;
}
/* .wrapper .banner-img p{
    
    color:black;
    text-align:center;
    font-size:40px;
    font-family:cursive; 
}
.wrapper .banner-img h1{
    
    color:black;
    text-align:center;
    padding-top: 200px;
    font-size:60px; 
} */
.wrapimage {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    }
.content2 img{

    border: 60px solid #015083;
    height: 400px;
    width: 500px;
}
.content2 {
    padding-top:60px;
}
.content h1{

    padding-top: 120px;
    color: white;
    font-size: 6rem;
}
.content p{
    color: white;
    font-size: 2rem;
    padding-left: 12px;
}

.nav-bar .nav-bar-left a{
    font-size:35px;
    color:#11c1f4; 
    
}

.nav-bar .nav-bar-right ul li{
    
    display: inline-block;
    width:90px; 
    text-align:center; 
}
.nav-bar .nav-bar-right ul li a{
color:#000;
    text-transform: uppercase;
    font-size: 12px;
    transition:all 0.5s ease; 
}
/* bhjb */
.IT{
    background-color:#686E84;
    padding: 10px;
    width: 100%;
}
.IT h1{
    color: white;
    text-align: center;
    font-size:3rem; 
    padding-bottom:10px; 
}
.IT p{
    color: white;
    text-align: center;
    font-size: 18px;
    font-family:Helvetica, sans-serif;
}
.IT_content {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    /* flex-flow: row; */
    padding: 30px;
    background: url(../Static/Images/service.png);
}
.IT_heading {
    padding-top: 100px;
}
.IT_heading_content {
    width: 365px;
    text-align: justify;
}
/* process IT */
.IT_process_heading{
    background-color:#4F5E98;
    width: 100%;

}
.IT_process_heading h1{
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
    color: white;
}
.process_all_wrap {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
   
    padding-bottom:40px;
    padding-top:40px;
    background: #C6C6C6;
}
.process_image img {
    height: 250px;
    width:400px;
    padding-top: 40px;
}
.process_image_content p{
    font-weight: bold;
}
.process_image_content {
    width: 494px;
    text-align: justify;
}
/* ggggggggggggfffffffffffffffffffffffffffffffffffffffffffffffffffff */
@media screen and (max-width:768px){
    
    .nav-bar{
        flex-direction:column;
        height: auto;
        align-items: center;
        
    }
    
    .hamburger{
        
        display: block;
        position: absolute;
        top:20px;
        right:20px; 
    }
    
    .nav-bar .nav-bar-left a{
        font-size:24px; 
        
    }
    
    .nav-bar .nav-bar-right ul li {
        
        display: block;
        padding:10px; 
        
        
    }
    
    
    .nav-bar .nav-bar-right{
        
        display:none;
        
    }
    .nav-bar-right.active{
        display: block;
    }
    
    
    .wrapper .banner-img{
        height:500px;
        width:100%;
        background: linear-gradient(
                         rgba(20,20,20, .5), 
                         rgba(20,20,20, .5)),
                         url(../Static/Images/write-593333_960_720.jpg);
        background-repeat:no-repeat;
        background-size: cover;
        background-position: center;
        color: white;
        padding-top:200px;
        text-align: center;
    
        
    }
    .banner-img h1{
        font-size:31px; 
    }
    .banner-img p{
        font-size: 14px;
        font-family: cursive;
    }
.wrapimage {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding-top: 20px;
    
}



}



/*  */

@media screen and (max-width:768px){
    .IT_heading {
        padding-top: 0px;
        font-size: 10px;
        /* width: 100%; */
        padding-bottom: 10px;
    }
    .IT_heading_content {
        width: 365px;
        text-align: justify;
        font-size: 12px;
    }
    .process_image_content {
        width: 494px;
        text-align: justify;
        padding-right: 20px;
        padding-left: 20px;
        font-size: 12px;
    }
    .IT h1 {
        color: white;
        text-align: center;
        font-size: 2rem;
        padding-bottom: 10px;
    }
    .IT_process_heading h1 {
        text-align: center;
        padding-top: 30px;
        padding-bottom: 30px;
        color: white;
        font-size: 22px;
    }
}