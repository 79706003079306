.footer{

    background:black;
    /* background: url(../Static/Images/brick.jpg) no-repeat center; */
    height:220px;
    width: 100%;
    display: flex;
    flex-flow: row;
    align-items: center;
    flex-wrap: wrap;
    justify-items: center;
    margin: 0 auto;

}

.footercontacts{

    
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 50%;
    /* justify-content: space-evenly; */
    padding: 20px;
    /* padding-left: 60px; */

}

.footercontactsitem{

    font-size: 25px;
    padding: 40px;
    
}

.footercontactsitem>a{
    color: white;
}




.footer-navdown{

    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 50%;
    justify-content: space-evenly;
    padding: 20px;
    

}

.footernav-item{

    padding: 20px;
    font-size: 16px;
    
}

.footernav-item>a{

    
    color: white;
}

.Copyright{

    width: 100%;
    text-align: center;
    
}

@media screen and (max-width:768px){

.footer{

    width: 100%;
    height: auto ;
}


















}