
h1{
    text-align: center;
}


.Aboutus-banner{

background: url(../Static/Images/Aboutus.jpg) no-repeat center;
background-size: cover;
/* background-attachment: fixed; */
height: 450px;
width:100%;
text-align: center;
color:white;
display: flex;
flex-flow: column;
flex-wrap: wrap;
align-items: center; 
align-content: center;
justify-content: center;
justify-items: center;
margin: 0 auto;
}

.Aboutus-header>h1{

    padding: 20px;
    font-size: 40px;
    font-family: 'Roboto Slab', serif;
    text-align: center;
}

.Aboutus-contentbox{

    height: auto;
    width: 50%;
    text-align: justify;
    border: 3px solid black;
    border-radius: 0.75rem;
    box-shadow: 0px 0px 1px 3px;
    padding: 20px;
    opacity: 0.7;
    background-color: #ffffff;
    
}

.Aboutus-contentbox>p{

    font-size: 20px;
    color: black;
    font-weight: bold;
    font-family: monospace;
}


.Aboutus-info{

    /* background: url(../Static/Images/Aboutus.jpg) no-repeat center; */
background-size: cover;
height: auto;
width:100%;
display: flex;
flex-flow: row;
flex-wrap: wrap;
margin: 0 auto;
padding: 20px;


}

.Aboutus-infolist{

    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    width: 98%;
    justify-content: space-evenly;
    padding: 40px;
}

.establish{

    border:4px solid black;
    border-radius:0.5rem;
    box-shadow: 5px 10px lightgrey ;
    width:48%;
    height:auto;
    padding: 10px;
    text-align: center;
    text-align: justify;
 
}

.establish-header>h1{

    text-align: center;
    padding: 10px;
    font-family: 'Crimson Pro', serif;
    font-size: 4rem;
    
}

.establish-content>p{
    font-size: 15px;
    padding: 15px;
    font-family: 'Crimson Pro', serif;
    font-size: 18px;
}


.Team{

    background: url(../Static/Images/black.jpg) no-repeat center;        
    background-size: cover;
    height: auto;
    width:100%;
    display: flex;  
    flex-flow: column;
    flex-wrap: wrap;    
    margin: 0 auto;
    padding: 20px;
}


.Team-header>h1{

    padding: 20px;
    color: white;
    text-align: center;
}

.Team-list{

    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    width: 98%;
    justify-content: space-evenly;


}

.Team-itembox{

    border:4px solid white;
    border-radius:0.5rem;
    box-shadow: 5px 10px ;
    width:28%;
    height:auto;
    /* padding: 10px;
    padding-left: 57px;
    text-align: center;
    text-align: justify; */
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    


}


.Team-img>img{

    height: 200px;
    width: 200px;
    padding: 20px;
    margin-top: 50px;

}

.Team-content>h3{

    font-size: 20px;
    padding-bottom: 15px;
    color: white;
}

.Team-content>p{

    color:white;
    padding: 30px;
    text-align: justify;    
}

.certificate{
  height: 700px;
  display: flex;
  justify-content: center;
  padding-top: 50px;
}

.certificate-sub{
  width: 80%;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 50px;
}

.certificate-sub>h2{
  font-size: 20px;
  font-weight: 700;
  font-style: italic;
}

.certificate-sub>img{
  height: 400px;
  width: 650px;
  margin-top: 50px;
}


@media screen and (max-width:768px){

    .Aboutus-banner{
        width: 100%;
        height: auto;
    }

    .Aboutus-header>h1{

        width: 100%;
        font-size: 35px;
    }
    
    .Aboutus-contentbox{

        width: 85%;
        height: auto;
        padding: 20px;
        margin: 20px;
    }

    .establish{

        height: auto;
        width: 100%;
        margin: 30px;
    }

    .Team{
        height: auto;
    }

    .Team-itembox{

        height: auto;
        width: 100%;
        margin: 10px;
    }

     .certificate{
        
        height:480px;
    }
    .certificate-sub{
      width: 90%;
      display: flex;
      flex-flow: column;
      align-items: center;
      padding: 10px;
    }

    .certificate-sub>h2{
      font-size: 18px;
      font-weight: 700;
      font-style: italic;
    }

    .certificate-sub>img{
      height: 270px;
      width: 355px;
      margin-top: 20px;
    }
}