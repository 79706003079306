.mobapp-banner{

    background-image: url(./../Static/Images/mobappbanner.jpg);
    background-attachment: fixed;
    background-size: cover;
    height: 700px;
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
}
.mobapp-header{

    width: 50%;
    display: flex;
    justify-content: center;
}
.mobapp-header>h1{

    font-size: 60px;
    color: white;
    font-family: 'Roboto Slab', serif;
    text-align: justify;
        
}

.mobapp-define{

    /* background-image: url(./../Static/Images/aidefine.jpg);
    background-size: cover; */
    height: 400px;
    width: 100%;
    display: flex;
    align-items: center;
    opacity: 0.8;
}

.mobappdefine-list{

    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 40px;
    justify-content: space-evenly;
    align-items: center;

    
}

.mobappdefine-item{

    border-right: 6px solid gray;
    width: 48%;
    height: auto;
}

.mobappdefine-gif{
    width: 40%;
}
.mobappdefine-itembox{

    border: 2px solid black;
    box-shadow: 4px 6px black;
    border-radius: 2rem;
    width: 70%;
    height: auto;
    padding: 20px;
    background-color: #4B6B20;
    
    

}

.mobappdefine-content>p{

    font-size: 20px;
    text-align: justify;
    color: white;
    font-family: monospace;

}

.mobapp-services{

    background-image: url(./../Static/Images/appservice.jpg);
    background-size: cover;
    height: auto;
    width: 100%;
    padding: 50px;
   
}

.mobappservices-header>h1{
    border-bottom: 1.2px solid black;
    padding-bottom: 25px;
    color: white;
    text-align: center;
}

.mobappservices-list{

    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
}

.mobappservices-item{

    border: 2px solid black;
    box-shadow: 4px 6px black;
    border-radius: 2rem;
    width: 40%;
    height: auto;
    padding: 20px;
    background-color: white;
    opacity: 0.9;
    margin: 20px;

}

.mobappservicesitem-header>h3{

    font-weight: 600;
    padding-bottom: 15px;
    border-bottom: 1px solid gray;
}

.mobappservicesitem-content>p{

    font-weight: 700;
    color: black;
    text-align: justify;
    font-size: 17px;
}
.mobappdefine-gif>img{
    height: 300px;
    width: 450px;
}
@media screen and (max-width:768px){

    .mobapp-banner{
        width: 100%;
        height: 500px;
    }

    .mobapp-header{

        width: 100%;
    }

    .mobapp-header>h1{

        font-size: 40px;

    }

    .mobapp-define{
        width: 100%;
        height: 600px;
    }

    .mobappdefine-item{
        width: 100%;
        border-right: none;
    }
    .mobappdefine-itembox{
        width: 100%;
        margin-bottom: 50px;
        
    }

    .mobappdefine-content>p{
        font-size: 12px;
    }

    .mobappdefine-gif{
        width: 100%;
    }

    .mobappdefine-gif>img{
        height: 250px;
        width: 100%;
        
    }
    .mobapp-services{

        height: auto;

    }

    .mobappservices-header>h1{

        font-size: 20px;
        font-weight: 600;
    }
    .mobappservices-item{
        width: 100%;
        height: auto;
        margin-bottom: 20px;
    }

    .mobappservicesitem-header>h3{

        font-size: 16px;
    }
    .mobappservicesitem-content>p{
        font-size: 13px;
    }






}

