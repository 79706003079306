.navbar{

  
    height: 8.2rem;
    margin-bottom: unset;
    border: none;
    background: white;
    
    
}


.navHead{
    width: 48%;
}


/* .navbar-itembox{
    display: flex;
    flex-flow: row; */
    /* justify-content: space-evenly; */
    /* width: 48%;
    list-style: none;
    align-items: center;
    
} */

li>a{
    color:black;
    font-size: 1.45rem;
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    
    margin-right: 30px;
}

a:hover{

    /* color:blue; */
}

#navicons{

    margin: 10px;
}

a>img{
    height: 80px;
    width: 300px;
}
.try{

   
   
    display: block;
    transition: font-size 1s;
    
}

.try:hover {
   
    font-size: 20px;
    color: black;
  }

@media screen and (max-width:768px){

    .navbar {
        width: 100%;
        display: block;
        background: white;


    }

    .navbar-nav{

        width: 118%;

    }

    li{
        width: 100%;
    }

    li>a{

        display: block;
        background: white;
        text-align: center;

    }

    a>img{

        height: 60px;
        width: 250px;
    }




}