.career-banner{


    background: url(../Static/Images/career.jpg);
    background-size: cover;
    background-attachment: fixed;
    height: 700px;
    width:100%;
    
    text-align: center;
    color:white;
    display: flex;
    flex-flow: row;
    
    align-content: center;
    
    flex-wrap: wrap;

    justify-content: flex-start;
    margin: 0 auto;
}


.career-header>h1{

    font-size: 100px;
    font-family: 'Crimson Pro', serif;
    color: #001422;
    padding-left: 70px;
    
 

}

.career-header>p{

    font-size: 18px;
    color: black;
    font-weight: 600;
    margin-left: 80px;
}

.career{

    background-color:lavender;
    background-size: cover;
    background-attachment: fixed;
    height: auto;
    width:100%;
    text-align: center;
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    justify-items: center;
    margin: 0 auto;
    padding: 20px;


}

.profile{

    width:20%;
    height:100px;
    justify-content: center;
    padding-top: 10px;
    text-align: center;
    border: 2px solid black;
    border-radius: 15%; 
    box-shadow: 5px 10px ;
    align-content: center;
}

.career-info{


    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    justify-content:center;
    width: 100%;
    /* justify-content: space-evenly; */
    padding: 40px;
    align-content: center;
    /* margin-left: 220px; */

}
.career-item{

    width:60%;
    height:auto;
    justify-content: center;
    padding-top: 10px;
    padding: 20px;
    text-align: center;
    border: 2px solid black;
    border-radius:0.5rem; 
    box-shadow: 5px 8px ;
    margin: 20px;
    text-align: justify;
}

.career-item>h4{

    text-align: center;

}

@media screen and (max-width:768px){


    .career-banner{
        width: 100%;
        height: 500px;

    }

    
    .career-header>h1{
    
        font-size: 50px;
    
    }

    .career-header>p{

        font-size: 18px;
       
        text-align: center;
    }
    

.profile{
    height: auto;
    width: 150px;
}

.career-info{

    height: auto;
    width: 100%;
}

.career-item{

    height: auto;
    width: 100%;
}

















}