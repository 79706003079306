.webapp-banner{

    background-image: url(./../Static/Images/webbanner.jpg);
    background-attachment: fixed;
    background-size: cover;
    height: 720px;
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    align-items: center;
}

.Webapp-header{

    width: 50%;
    
}
.Webapp-header>h1{

    font-size: 60px;
    color: white;
    font-family: 'Roboto Slab', serif;
        
}

.webapp-define{

    background-image: url(./../Static/Images/webappdef.jpg);
    background-size: cover;
    height: 500px;
    width: 100%;
    display: flex;
    align-items: center;
}

.webappdefine-list{

    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 40px;
    justify-content: space-evenly;
    align-items: center;

    
}

.webappdefine-item{

    border: 2px solid black;
    box-shadow: 4px 6px black;
    border-radius: 2rem;
    width: 40%;
    height: auto;
    padding: 20px;
    background-color: white;
    opacity: 0.8;

}

.webappdefine-content>p{

    font-size: 20px;
    text-align: justify;

}

.webapp-services{

    background-image: url(./../Static/Images/webappservice.jpg);
    background-size: cover;
    height: auto;
    width: 100%;
    padding: 50px;
}

.webappservices-header>h1{
    border-bottom: 1.2px solid black;
    padding-bottom: 25px;
    text-align: center;
}

.webappservices-list{

    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
}

.webappservices-item{

    border: 2px solid black;
    box-shadow: 4px 6px black;
    border-radius: 2rem;
    width: 35%;
    height: auto;
    padding: 20px;
    background-color: white;
    opacity: 0.9;
    margin: 20px;

}

.webappservicesitem-header>h3{

    font-weight: 600;
    padding-bottom: 18px;
}

.webappservicesitem-content>p{

    font-weight: 200;
    color: black;
    text-align: justify;
}

@media screen and (max-width:768px){

    .webapp-banner{
        width: 100%;
        height: 500px;
    }

    .Webapp-header{

        width: 100%;
    }

    .Webapp-header>h1{

        font-size: 40px;

    }

    .webapp-define{
        width: 100%;
        height: auto;
    }

    .webappdefine-item{
        width: 100%;
        margin-bottom: 50px;
    }

    .webappdefine-content>p{
        font-size: 12px;
    }

    .webappdefine-gif>img{
        height: 200px;
        width: 250px;
    }

    .webappservices-item{
        width: 100%;
        height: auto;
    }

    .webappservicesitem-content>p{

        font-size: 13px;
    }





}