.iot-banner{

    background-image: url(./../Static/Images/iotbanner.jpg);
    background-attachment: fixed;
    background-size: cover;
    height: 700px;
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
}
.iot-header{

    width: 50%;
    display: flex;
    justify-content: center;
}
.iot-header>h1{

    font-size: 60px;
    color: white;
    font-family: 'Roboto Slab', serif;
    text-align: justify;
        
}

.iot-define{

    /* background-image: url(./../Static/Images/aidefine.jpg);
    background-size: cover; */
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    opacity: 0.8;
}

.iotdefine-list{

    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 40px;
    justify-content: space-evenly;
    align-items: center;

    
}

.iotdefine-item{

    border-right: 6px solid grey;
    width: 50%;
    height: auto;
}

.iotdefine-gif{
    width: 40%;
}
.iotdefine-itembox{

    border: 2px solid black;
    box-shadow: 4px 6px black;
    border-radius: 2rem;
    width: 80%;
    height: auto;
    padding: 20px;
    background-color: #114D71;
    
    

}

.iotdefine-content>p{

    font-size: 18px;
    text-align: justify;
    color: white;
    font-family: monospace;

}

.iotpoints-list{
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.iotpoints-list>ul>li{
    font-size: 18px;
    text-align: justify;
    color: #09476C;
    margin: 5px;
    font-weight: 550;
}
.iot-services{

    background-image: url(./../Static/Images/iotservice.jpg);
    background-size: cover;
    height: auto;
    width: 100%;
    padding: 50px;
   
}

.iotservices-header>h1{
    border-bottom: 1.2px solid white;
    padding-bottom: 25px;
    color: white;
    text-align: center;
}

.iotservices-list{

    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
}

.iotservices-item{

    border: 2px solid black;
    box-shadow: 4px 6px black;
    border-radius: 2rem;
    width: 40%;
    height: auto;
    padding: 25px;
    background-color: white;
    opacity: 0.8;
    margin: 20px;

}

.iotservicesitem-header>h3{

    font-weight: 600;
    padding-bottom: 15px;
    border-bottom: 1px solid gray;
}

.iotservicesitem-content>p{

    font-weight: 700;
    color: black;
    text-align: justify;
    font-size: 17px;
}
.iotdefine-gif>img{
    height: 300px;
    width: 450px;
}
@media screen and (max-width:768px){

    .iot-banner{
        width: 100%;
        height: 500px;
    }

    .iot-header{

        width: 100%;
    }

    .iot-header>h1{

        font-size: 40px;

    }

    .iot-define{
        width: 100%;
        height: 600px;
    }

    .iotdefine-item{
        width: 100%;
        border-right: none;
    }
    .iotdefine-itembox{
        width: 100%;
        margin-bottom: 50px;
        
    }
    .iotpoints-list>ul>li{

        font-size: 13px;
    }
    .iotdefine-content>p{
        font-size: 12px;
    }

    .iotdefine-gif{
        width: 100%;
    }

    .iotdefine-gif>img{
        height: 250px;
        width: 100%;
        
    }
    .iot-services{

        height: auto;

    }

    .iotservices-header>h1{

        font-size: 20px;
        font-weight: 600;
    }
    .iotservices-item{
        width: 100%;
        height: auto;
        margin-bottom: 20px;
    }

    .iotservicesitem-header>h3{

        font-size: 16px;
    }
    .iotservicesitem-content>p{
        font-size: 13px;
    }






}

