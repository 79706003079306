.banner-img{
    height:550px;
    width:100%;
    background:url(../Static/Images/blue.png); 
    background-repeat:no-repeat;
    background-size: cover;
    background-position: center;
    
}
.Digitalmarket {
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 50px;
}

.DigiatlleftContent{

    width: 48%;
    
}
.Digiatlimage{

    width: 50%;
}
.Digiatlimage img{

    width: 40rem;
    padding-top:80px;
   
}
.learn>a{
    
    font-size: 20px; 
    font-weight: 10px;
    width: 128px;
    height: 45px;
    border: 1px solid brown;
    background: brown;
    border-radius: 5px;
    /* justify-content: center; */
    margin-top: 40px;
    color: white;
    margin-right: 40px;
    justify-content: flex-start;
    display: flex;
    

}



.DigiatlleftContent{

    padding: 10px;
    padding-left: 100px;
   
    
}
.DigiatlleftContent>h1 {
    color: white;
    padding-top: 84px;
    font-size: 6rem;
    text-align: justify;
}
.DigiatlleftContent>p{

    color:white;
    font-size:19px;  
    text-align: justify;
}

.services{


    background:lightgrey;
    
    /* background: url(../Static/Images/brick.jpg) no-repeat center; */
    height:auto;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    flex-wrap: wrap;
    justify-items: center;
    margin: 0 auto;
}

.allProducts{

    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    width: 88%;


}
.logoHeading img{

width:13rem;

}
.heading {
    font-weight: bold;
    font-size: 25px;
    padding-bottom: 13px;
}
.firstlogo{
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    text-align: center;
    justify-content: space-evenly;
    margin: 0 auto 2.75rem;
    box-shadow: 0 0 11px 2px #555;
    border-radius: .9375rem;
    align-items: center;
    width:28rem;
    padding: 2%;
    background-color:#fff;
    background: white;
}
.ourProduct{


    text-align: center;
    padding-top: 10px;
    padding-bottom: 18px;
}


