.blog-header{

    background-color: #2F4F4F;
    height: 150px;
    align-items: center;
    display: flex;
    justify-content: center;


}

.blog-header>h1{

    font-size: 60px;
    color: white;
    font-family: 'Crimson Pro', serif;
}


.blog-layout{

    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    height: auto;
}
.blog{

    height: auto;
    width: 70%;
    padding-top: 40px;
    padding-left: 40px;
    padding-bottom: 40px;
}
.blog-list{

    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* align-items: center; */
    width: 100%;
    height: auto;
}

.blog-item{
  
    height: auto;
    width: 85%;
    margin: 30px;
}

.blog-img>img{
    height: 500px;
    width: 100%;
    border-radius: 1rem;
}

.blog-heading>h1{

    text-align: justify;
    padding-top: 20px;
    font-family: monospace;
}
h1{

    text-align: justify;
}
.blog-content>p{

    text-align: justify;
    font-size: 18px;
    font-family: cursive;
    padding-top: 30px;


}

.blog-button{

    display: flex;
    justify-content: flex-start;
    margin-top: 30px;
}



.blog-category{

    width: 30%;
    height: auto;
    padding: 20px;
    text-align: center;
   
   
}

.blogcategory-header{

    width: 80%;
}

.blogcategory-list{

    border: 2px solid black;
    box-shadow: 2px 3px solid black;
    border-radius: 1rem;
    width: 80%;
    text-align: justify;
    height: auto;
    display: flex;
    justify-content: space-evenly;
    
  
}

.blogcategory-list>ol>li>a{

    margin: 40px;
    text-align: justify;
    font-size: 15px;
    font-family: monospace;
    font-weight: 600;
    border-bottom: 1.2px solid black;
    
}