.contactus-banner{

    background: url(../Static/Images/apple.jpg) no-repeat center;
    background-size: cover;
    background-attachment: fixed;
    height: 400px;
    width:100%;
    text-align: center;
    color:white;
    display: flex;
    flex-flow: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    justify-items: center;
    margin: 0 auto;
}

.contactus-header>h1{

    font-size: 50px;


}
.contactus-header>p{

    font-size: 18px;


}

.address{

    
    
background-size: cover;
background-attachment: fixed;
height: 500px;
width:100%;
/* background-image: url('../Static/Images/texture.jpg'); */
text-align: center;
display: flex;
flex-flow: row;
align-items: center;
align-content: center;
justify-content: center;
flex-wrap: wrap;
justify-items: center;
margin: 0 auto;

    
}

.address-list{

    
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    width: 98%;
    /* text-align: justify; */
    justify-content: space-evenly;
}

.address-item{

    width:25%;
    height:auto;
    justify-content: center;
    padding: 20px;
    text-align: center;
    border: 2px solid black;
    border-radius: 15%; 
    box-shadow: 5px 10px ;
    
}

.address-itemheader>h2{

    /* color: white; */
}

.address-itemheader>p{

    /* padding: 20px; */
    font-size: 15px;
    /* color: white; */
}

.contactus-pic>img{

    height: 300px;
    width: 400px;
}

.form{

    
    height: auto;
    width: 70%;
    justify-content: center;
    display: flex;
    flex-flow: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    justify-items: center;
    margin: 0 auto;
    padding: 30px;
}
.container{
    width: 60%;
    justify-content: center;
    text-align: center;
    

}

.jumbotron>h1{

    font-size: 40px;
    text-align: center;
}

.jumbotron>h2{

    font-size: 18px;
}

.form-group{

    width: 100%;
    text-align: start;
    justify-content: center;
}

.form-group>button{

    /* margin-left: 100px; */
    
}

.form-banner{

    width: 100%;
    background-color: grey;
}

@media screen and (max-width:768px){


    .address{

        height: auto;
    }

.address-item{

    height: auto;
    width: 70%;
    margin: 20px;
}

.form{

    width: 100%;
   
   
}


.form-group{

    width: 100%;
}

.container{

    width: 100%;
}






















}